import { UseFormRegisterReturn } from 'react-hook-form';
import {
  ChevronDown,
  ErrorIcon,
  LoadingIcon,
  SuccessIcon,
  WarningIcon,
} from 'src/components/Auth/_components/Icons';

type StatusOptions = 'warning' | 'error' | 'success' | 'loading' | undefined;

interface SelectPropsType {
  id: string;
  name: string;
  label: string;
  status: StatusOptions;
  size?: 'sm' | 'md' | 'lg';
  required: boolean;
  register?: UseFormRegisterReturn;
  errorMessage?: string;
  list: { value: string; text?: string }[];
  leadingIcon?: JSX.Element | string;
}

interface StatusIconType {
  status: string | undefined;
}

const StatusIcon = ({ status }: StatusIconType) => {
  return (
    <span className="mr-16 form-input-trailing-icon" aria-hidden="true">
      {status === 'error' && <ErrorIcon className="fill-red" />}
      {status === 'success' && <SuccessIcon className="fill-green-dark" />}
      {status === 'warning' && <WarningIcon className="fill-yellow-dark" />}
      {status === 'loading' && <LoadingIcon className="fill-teal" />}
    </span>
  );
};

const fieldSize = {
  sm: 'pl-12 pr-36 pt-20 h-48 text-md',
  md: 'pl-14 pr-40 pt-24 h-56 text-md',
  lg: 'pl-16 pr-44 pt-28 h-64 text-lg',
};

const borderColor = (status: StatusOptions) => {
  return status === 'error'
    ? 'border-red/60 hover:border-red-dark hover:ring-red/40 hover:ring-1 focus-visible:border-red focus-visible:ring-red focus-visible:ring-2 outline-none focus-visible:outline-none'
    : 'border-gray-dark/50 hover:border-gray-dark hover:ring-gray-dark/40 hover:ring-1 focus:border-teal-darker/60 focus:ring-teal-darker focus:ring-2';
};

const labelSize = {
  valueSelected: {
    sm: "peer-[:not(option[value='']):valid]:top-6 pl-6",
    md: "peer-[:not(option[value='']):valid]:top-8 pl-2",
    lg: "peer-[:not(option[value='']):valid]:top-12 pl-2",
  },
  defaultNoValue: {
    sm: 'left-12 text-md',
    md: 'left-14 text-md',
    lg: 'left-16 text-lg',
  },
};
// leftDistance provides additional space to compensate for the presence of a leading icon
const leftDistance = {
  sm: 'left-[34px]',
  md: 'left-[36px]',
  lg: 'left-[37px]',
};

const Select = ({
  id,
  name,
  label,
  status,
  size = 'md',
  required,
  register,
  errorMessage,
  list,
  leadingIcon,
}: SelectPropsType) => {
  return (
    <div className="relative form-input-wrapper border-gray-dark">
      <div className="relative w-full">
        <select
          className={`bg-white text-gray-dark text-ellipsis overflow-hidden pl-14 appearance-none peer block w-full rounded-md border focus-visible:outline-none transition-all ease-in-out duration-200
          ${fieldSize[size]}
          ${leadingIcon && 'pl-36'}
          ${borderColor(status)}
          `}
          name={name}
          id={id}
          data-testid={`${id}-testid`}
          aria-label="Choose a text"
          aria-describedby="text-helper"
          required={required}
          {...register}
        >
          <option value="" data-testid="default-selection" selected disabled hidden></option>
          {list.map(({ value, text = value }, index) => (
            <option data-testid="select-option" key={value + index} value={value || text}>
              {text}
            </option>
          ))}
        </select>
        <div className="form-input-leading-icon">{leadingIcon}</div>
        <label
          className={`form-select-label absolute mt-2 origin-top-left leading-none peer-focus:text-teal-darker pointer-events-none select-none transition-all ease-in-out duration-300 top-1/2 -translate-y-1/2 text-gray-dark peer-[:not(option[value='']):valid]:-translate-y-0 peer-[:not(option[value='']):valid]:scale-75 ${
            labelSize.defaultNoValue[size]
          } ${labelSize.valueSelected[size]} ${leadingIcon ? leftDistance[size] : ''}`}
          htmlFor={id}
        >
          {label}
        </label>
        <span
          className="absolute inset-y-0 flex items-center gap-16 pointer-events-none right-14"
          aria-hidden="true"
        >
          <StatusIcon status={status} />
          <ChevronDown className="fill-teal-darker" />
        </span>
      </div>
      {errorMessage && (
        <div className="text-xs peer-invalid:visible text-red" id="select-error-message">
          <div data-testid="select-error-message" className="mt-4" role="region">
            {errorMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
