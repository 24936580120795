/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import { FormsMessage } from 'src/components/Common/@electron/FormsMessage';
import track from 'src/lib/Analytics';
import Logger from 'src/lib/Logger';
import { useSitecoreContext } from 'src/lib/SitecoreContextFactory';
import Select from '../../components/Select';
import { getJson } from '../api/getJson';
import { PEDataItemType, ProjectOptionsType } from '../api/jsonTypes';
import BottomButtons from './components/BottomButtons';
import RebateCards from './components/RebateCards';
import Results from './components/Results';
import SkeletonLoader from './components/SkeletonLoader';
import { SitecoreDataType } from './data';
import { PEComponentProps } from './types';

export const CompactFormsMessageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-white p-24 container-md rounded-md">{children}</div>
);

const ProjectEstimatorComponent = ({ json, containerRef, headline }: PEComponentProps) => {
  const [view, setView] = useState<'initial' | 'results' | 'noresults'>('initial');
  const [estimateNumbers, setEstimateNumbers] = useState<PEDataItemType[]>([]);
  const [project, setProject] = useState<ProjectOptionsType | ''>('');
  const content = json?.content;
  // getting find a contractor link from top tasks, not sure if this is overkill
  const backupLink = {
    href: '/find-a-contractor',
  };
  const { getComponent } = useSitecoreContext();
  const topTasksContent = getComponent('Top Tasks');
  const findContractorItem = topTasksContent?.[0]?.fields?.items?.filter((item: any) =>
    item.name?.toLowerCase().includes('contractor')
  );
  const findContractorLink = findContractorItem?.[0]?.fields?.URL?.value || backupLink;

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      pceState: '',
      pceProject: '',
    },
  });

  const stateOptions = [...new Set(json?.numbers.map((item: PEDataItemType) => item.State))].map(
    text => ({
      value: text,
    })
  );
  const projectOptions = [
    ...new Set(json?.numbers.map((item: PEDataItemType) => item.Project)),
  ].map(text => ({
    value: text,
  }));

  const sendEstimateClickAnalytics = () => {
    const buttonText = content['Estimate Button Text'] || 'Get Estimate';
    track.component({
      category: 'fid-project-estimator',
      action: buttonText,
      label: headline || 'na',
      event: 'event-click',
    });
  };

  const sendFindContractorClickAnalytics = () => {
    const buttonText = content['Primary Button Text'] || 'na';
    track.component({
      category: 'project-estimator',
      action: buttonText,
      // eslint-disable-next-line no-template-curly-in-string
      label: content['Result Title']?.replace('${project}', project),
      event: 'event-click',
    });
  };

  const sendRebateClickAnalytics = (href: string, text: string) => {
    const rebateCardsTitle = project ? json.rebates?.[project]?.title : null;
    track.component({
      category: 'fid-rebates',
      action: `${text?.replace(' & ', '-').replace(' - ', '-') || 'na'}-|- ${
        href?.replace('https://', '') || 'na'
      }`,
      label: rebateCardsTitle || 'na',
      event: 'event-click',
    });
  };

  const onSubmit = () => {
    const project = getValues('pceProject');
    const state = getValues('pceState');
    const results = json?.numbers.filter(
      (item: any) => item.Project === project && item.State === state
    );
    sendEstimateClickAnalytics();
    // no results for florida insulation upgrades, etc
    // three results for hvac except one for florida
    if (results?.length) {
      setEstimateNumbers(results);
      setView('results');
      setProject(project as ProjectOptionsType);
    } else {
      setView('noresults');
    }
  };

  const startOver = () => {
    setView('initial');
    reset();
    // scroll to top of project estimator
    const estimateProjectTopPos = containerRef.current?.getBoundingClientRect().top || 0;
    if (estimateProjectTopPos < -35) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (view === 'initial' || view === 'noresults') {
    return (
      <>
        {view === 'noresults' && (
          <CompactFormsMessageContainer>
            <FormsMessage
              variant="info"
              size="compact"
              title={content['No Results Title']}
              description={content['No Results Description']}
            />
          </CompactFormsMessageContainer>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="flex flex-col md:flex-row md:flex-wrap container-xs md:container-2xl p-24 gap-24 md:gap-32 justify-center text-gray-dark">
            <div className="w-full md:basis-288">
              <Select
                id="PCEProjectDropdown"
                name="PCEProject"
                label={content['Project Label']}
                status={errors.pceProject ? 'error' : undefined}
                required={true}
                register={register('pceProject', {
                  required: content['Project Input Error Message'],
                })}
                errorMessage={errors.pceProject?.message}
                list={projectOptions}
              />
            </div>
            <div className="w-full md:basis-288">
              <Select
                id="PCEStateDropdown"
                name="PCEState"
                label={content['State Label']}
                status={errors.pceState ? 'error' : undefined}
                required={true}
                register={register('pceState', {
                  required: content['State Input Error Message'],
                })}
                errorMessage={errors.pceState?.message}
                list={stateOptions}
              />
            </div>
            <button type="submit" className="btn btn-primary w-full md:w-auto">
              {content['Estimate Button Text'] || 'Get Estimate'}
            </button>
          </div>
        </form>
      </>
    );
  }

  if (view === 'results' && project) {
    return (
      <div className="container-4xl">
        <div className="flex flex-col lg:flex-row gap-24 lg:gap-36 mb-24 lg:mb-32">
          <div className="lg:max-w-3/5">
            <Results
              data={estimateNumbers}
              // eslint-disable-next-line no-template-curly-in-string
              title={content['Result Title']?.replace('${project}', project)}
              description={content['Project Descriptions'][project]}
              seerExplanation={content['SEER Explanation']}
              tableHeaders={content['Table Headers']}
              tableProjectType={
                project.toLowerCase().includes('hvac')
                  ? 'hvac'
                  : project.toLowerCase().includes('solar')
                  ? 'solar'
                  : ''
              }
            />
          </div>
          <RebateCards rebateData={json.rebates[project]} {...{ sendRebateClickAnalytics }} />
        </div>
        <div className="container-sm">
          <BottomButtons
            startOver={startOver}
            findContractorLink={findContractorLink}
            {...{ sendFindContractorClickAnalytics }}
            findContractorButtonText={content['Primary Button Text']}
            startOverButtonText={content['Secondary Button Text']}
            headerText={content['Text Above Buttons']}
          />
        </div>
      </div>
    );
  }
  return null;
};

const ProjectEstimator = (props: SitecoreDataType) => {
  const [jsonStatus, setJsonStatus] = useState<'load' | 'failure'>('load');
  const [json, setJson] = useState<PEComponentProps['json']>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { jsonContent, jsonRebates, jsonData } = props.fields;
  const contentUrl =
    jsonContent?.value?.href ||
    `/_/media/json/find it duke/project estimator/content?rev=${Math.random()
      .toString()
      .slice(2, 6)}`;
  const rebatesUrl =
    jsonRebates?.value?.href ||
    `/_/media/json/find it duke/project estimator/rebates?rev=${Math.random()
      .toString()
      .slice(2, 6)}`;
  const numbersUrl =
    jsonData?.value?.href ||
    `/_/media/json/find it duke/project estimator/data?rev=${Math.random().toString().slice(2, 6)}`;
  useEffect(() => {
    const doFetch = async () => {
      const [numbers, content, rebates] = await Promise.all([
        getJson(numbersUrl),
        getJson(contentUrl),
        getJson(rebatesUrl),
      ]);
      if (numbers && content) {
        setJson({ numbers, rebates, content });
      } else {
        setJsonStatus('failure');
        Logger(
          `Failed to load: ${numbers ? '' : 'data.json '}${rebates ? '' : 'rebates.json '}${
            content ? '' : 'content.json'
          }`,
          { componentName: 'ProjectEstimator' }
        );
      }
    };
    doFetch();
  }, []);

  return (
    <div ref={containerRef}>
      <CallToAction {...CallToActionComposition(props)}>
        {jsonStatus === 'load' && !json && <SkeletonLoader />}
        {json && (
          <ProjectEstimatorComponent
            json={json}
            headline={props.fields?.Headline?.value}
            {...{ containerRef }}
          />
        )}
        {jsonStatus === 'failure' && (
          <CompactFormsMessageContainer>
            <FormsMessage
              variant="info"
              size="compact"
              title="Something went wrong."
              description="We're having trouble displaying the project cost estimator right now. Please try again later. (111)"
            />
          </CompactFormsMessageContainer>
        )}
      </CallToAction>
    </div>
  );
};

export { ProjectEstimatorComponent, ProjectEstimator as default };
